@font-face {
  font-family: myriad-pro;
  src: url("MyriadPro-Bold-webfont.92cdb9fe.woff2") format("woff2"), url("MyriadPro-Bold-webfont.38d28f16.woff") format("woff"), url("MyriadPro-Bold-webfont.80ba67d6.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: myriad-pro;
  src: url("MyriadPro-Italic-webfont.4797cc05.woff2") format("woff2"), url("MyriadPro-Italic-webfont.833eaed3.woff") format("woff"), url("MyriadPro-Italic-webfont.7e96ba9d.otf") format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: myriad-pro;
  src: url("MyriadPro-Regular-webfont.fabc6e50.woff2") format("woff2"), url("MyriadPro-Regular-webfont.0e94caf8.woff") format("woff"), url("MyriadPro-Regular-webfont.8dc81dd2.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: myriad-pro;
  src: url("MyriadPro-Light-webfont.b96cad59.woff2") format("woff2"), url("MyriadPro-Light-webfont.e7613036.woff") format("woff"), url("MyriadPro-Light-webfont.67608dc3.otf") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 300;
}
/*# sourceMappingURL=index.8aa6e782.css.map */
