@font-face {
    font-family: "myriad-pro";
    src: url("/webfonts/MyriadPro-Bold-webfont.woff2") format("woff2"), url("/webfonts/MyriadPro-Bold-webfont.woff") format("woff"), url("/webfonts/MyriadPro-Bold-webfont.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "myriad-pro";
    src: url("/webfonts/MyriadPro-Italic-webfont.woff2") format("woff2"), url("/webfonts/MyriadPro-Italic-webfont.woff") format("woff"), url("/webfonts/MyriadPro-Italic-webfont.otf") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: "myriad-pro";
    src: url("/webfonts/MyriadPro-Regular-webfont.woff2") format("woff2"), url("/webfonts/MyriadPro-Regular-webfont.woff") format("woff"), url("/webfonts/MyriadPro-Regular-webfont.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "myriad-pro";
    src: url("/webfonts/MyriadPro-Light-webfont.woff2") format("woff2"), url("/webfonts/MyriadPro-Light-webfont.woff") format("woff"), url("/webfonts/MyriadPro-Light-webfont.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 300;
}

/* @font-face {
    font-family: "myriad-pro";
    src: url("/webfonts/MyriadPro-Semibold-webfont.woff2") format("woff2"), url("/webfonts/MyriadPro-Semibold-webfont.woff") format("woff"), url("/webfonts/MyriadPro-Semibold-webfont.otf") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 600;
} */